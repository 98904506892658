.install-body {
  min-height: 100vh;
  background-color: #edf0f3;
}
.install-body .install-header {
  height: 80px;
  line-height: 80px;
  margin-bottom: 20px;
  background-color: #ffffff;
}
.install-body .install-header .header-content {
  width: 1200px;
  margin: 0 auto;
}
.install-body .install-header .header-content .title {
  font-size: 18px;
  margin-left: 20px;
}
.install-body .install-header .header-content .install-logo {
  border-radius: 50%;
}
.install-body .install-header .header-content .header-right {
  text-align: right;
}
.install-body .install-content {
  width: 1200px;
  margin: 0 auto 50px;
  background-color: #ffffff;
  padding: 50px;
}
.install-body .install-content .steps-content {
  margin-top: 40px;
  border: 1px solid #e9e9e9;
  background-color: #fafafa;
  min-height: 500px;
}
.install-body .install-content .steps-content .step-body {
  height: 400px;
  overflow-y: scroll;
}
.install-body .install-content .steps-content .step-body .install-success {
  text-align: center;
}
.install-body .install-content .steps-content .step-body .install-success .install-success-icon {
  font-size: 60px;
  color: #52c41a;
}
.install-body .install-content .steps-content .step-body .install-fail {
  text-align: center;
}
.install-body .install-content .steps-content .step-body .install-fail .install-fail-icon {
  font-size: 60px;
  color: #ff4d4f;
}
.install-body .install-content .steps-content .step-body .result-title {
  color: #000000;
  font-size: 24px;
  line-height: 1.8;
  margin: 40px;
}
.install-body .install-content .steps-content .step-body .fail-message {
  font-size: 14px;
}
.install-body .install-content .steps-action {
  margin-top: 24px;
  text-align: right;
}
.install-body .install-content .server-card {
  margin-bottom: 30px;
}
.install-body .install-content .correct-icon {
  color: #52c41a;
}
.install-body .install-content .error-icon {
  color: #ff4d4f;
}
.install-body .install-content .medium-input {
  width: 200px;
}
.install-body .install-content .db-extra {
  margin-left: 10px;
}
.install-body .install-content .db-extra .tips-icon {
  margin-right: 10px;
}
