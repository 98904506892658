.install-body {
    min-height: 100vh;
    background-color: #edf0f3;

    .install-header {
        height: 80px;
        line-height: 80px;
        margin-bottom: 20px;
        background-color: #ffffff;

        .header-content {
            width: 1200px;
            margin: 0 auto;

            .title {
                font-size: 18px;
                margin-left: 20px;
            }

            .install-logo {
                border-radius: 50%;
            }

            .header-right {
                text-align: right;
            }
        }
    }

    .install-content {
        width: 1200px;
        margin: 0 auto 50px;
        background-color: #ffffff;
        padding: 50px;

        .steps-content {
            margin-top: 40px;
            border: 1px solid #e9e9e9;
            background-color: #fafafa;
            min-height: 500px;

            .step-body {
                height: 400px;
                overflow-y: scroll;

                .install-success {
                    text-align: center;

                    .install-success-icon {
                        font-size: 60px;
                        color: #52c41a;
                    }
                }

                .install-fail {
                    text-align: center;

                    .install-fail-icon {
                        font-size: 60px;
                        color: #ff4d4f;
                    }
                }

                .result-title {
                    color: #000000;
                    font-size: 24px;
                    line-height: 1.8;
                    margin: 40px;
                }

                .fail-message {
                    font-size: 14px;
                }
            }
        }

        .steps-action {
            margin-top: 24px;
            text-align: right;
        }

        .server-card {
            margin-bottom: 30px;
        }

        .correct-icon {
            color: #52c41a;
        }

        .error-icon {
            color: #ff4d4f;
        }

        .medium-input {
            width: 200px;
        }

        .db-extra {
            margin-left: 10px;

            .tips-icon {
                margin-right: 10px;
            }
        }
    }
}
@primary-color: #7f7fd5;@link-color: #86a8e7;